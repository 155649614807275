@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .show-content-bar {
    transform: translateX(0px);
  }
  .animate-enter {
    animation: fadeInUp 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
    opacity: 0;
  }
  .animate-point {
    animation: pointBlink 0.7s ease-out !important;
    border-radius: 4px;
  }
  .animate-fade-in {
    animation: fadeIn 0.2s ease-in-out;
  }
  .no-scroll {
    overflow: hidden;
  }

  .scrollbar-bunker::-webkit-scrollbar {
    background-color: transparent !important;
    width: 0.2pc !important;
  }

  .scrollbar-bunker::-webkit-scrollbar-button {
    background: transparent !important;
  }

  .scrollbar-bunker::-webkit-scrollbar-thumb {
    background: #363944 !important;
    border-radius: 01pc !important;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px) scale(0.95);
    opacity: 0;
  }
  60% {
    transform: translateY(-10px) scale(1.02);
    opacity: 1;
  }
  80% {
    transform: translateY(5px) scale(0.98);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    /* transform: translateY(8px) scale(0); */
    filter: blur(10px);
    opacity: 0%;
  }
  100% {
    filter: blur(0px);
    /* transform: translateY(0) scale(1); */
    opacity: 100%;
  }
}

@keyframes pointBlink {
  0% {
    background-color: #22d3ee26;
  }
  60% {
    background-color: #22d3ee17;
  }
  80% {
    background-color: #22d3ee11;
  }
  100% {
    background-color: #22d3ee00;
  }
}

* {
  /* scroll-behavior: smooth; */
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  background-color: transparent !important;
  width: 0.5pc;
}

::-webkit-scrollbar-button {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #22d3ee;
  border-radius: 1pc;
}

* {
  font-family: "Poppins", sans-serif;
  transition: all 0.1s ease-in-out;
}

.aa {
  background-image: url("./assets/svgs/bg.svg");
  background-position: center;
  background-size: contain;
  filter: opacity(50%);
}
/* #root {
  height: 100vh;
  width: 100vw;
} */

html {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "Poppins", sans-serif !important;
}

.epr-emoji-category-label {
  background: transparent !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

.epr_-kg0voo {
  display: none !important;
}

.epr_-4ag7tl {
  background-image: url("./assets/svgs/emojiCategory.svg") !important;
}

.epr_-6npj90 {
  --epr-category-icon-active-color: #22d3eeb3 !important;
}

.epr_-2zpaw9 {
  border: 1px solid var(--epr-category-icon-active-color) !important;
}

.epr_-8623pq {
  background-color: transparent !important;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
}

.ss {
  box-shadow: inset 1px 1px 40px rgb(0, 0, 0);
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

/* div#content-bar {
  width: calc(300px / 2);
} */

/* .ssss {
  width: calc(640px / 3.5);
  height: calc(480px / 3.5);
} */
